import React from "react"
import { graphql } from "gatsby"
import Sidebar from "../components/layout/sidebar"
import styled from "@emotion/styled"
import Gallery from "../components/common/gallery"

const DescriptionWrapper = styled.div`
  max-width: 90%;
  text-align: justify;
  margin-bottom: 2rem;
  p {
    margin-bottom: 0.5rem;
  }
  ${props => props.theme.tablet} {
    max-width: 100%;
  }
`

const DocumentsWrapper = styled.div`
  max-width: 90%;
  a {
    display: block;
    margin-bottom: 1rem;
    color: black;
    line-height: 1.5rem;
    &:before {
      position: relative;
      top: 7px;
      display: inline-block;
      background-size: 28px 30px;
      margin-right: 15px;
      background-image: url("/file-icon.svg");
      background-position: center;
      background-repeat: no-repeat;
      content: "";
      height: 30px;
      width: 30px;
      @media (max-width: 768px) {
        margin-right: 0px;
        background-size: 20px 20px;
      }
    }
  }
  ${props => props.theme.tablet} {
    max-width: 100%;
  }
`

const Heading = styled.span`
  display: block;
  margin-bottom: 1rem;
  font-weight: bolder;
`

const ContentWrapper = styled.div`
  .wp-block-gallery {
    display: none;
  }
  .wp-block-embed {
    max-width: 420px;
  }

  .wp-block-embed > div {
    position: relative;
    padding-bottom: 75%;
    height: 0px;
  }

  .wp-block-embed iframe {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
  }

  .wp-block-image {
    display: flex;
    flex-flow: column;
    margin: 0 auto;
    img {
      width: 100%;
      border: thin #c0c0c0 solid;
      height: auto;
    }
  }

  .videofiles {
    background: gray;
    .wp-block-image {
      padding: 0;
      height: 100%;
      img {
        height: 100% !important;
      }
    }
  }

  .wp-block-columns {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    box-sizing: border-box;
    margin: 0 -1rem;
    ${props => props.theme.tablet} {
      flex-direction: column;
      margin: 0;
    }
    .wp-block-column {
      width: 33%;
      height: auto;
      padding: 1rem;
      flex: 1;
      ${props => props.theme.tablet} {
        width: 100%;
        padding: 0 0 1rem 0;
      }
      box-sizing: border-box;
      .wp-block-file {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 90;
        font-size: 1.8rem;
        a {
          display: block;
          width: 100%;
          height: 100%;
          color: white;
          text-align: right;
        }
      }
    }
  }
  .wp-block-image {
    img {
      width: 100%;
      height: auto;
    }
  }
`

const Concept = props => {
  const {
    data: {
      wordPress: { concept },
    },
  } = props

  const { title, content, conceptCF } = concept

  return (
    <Sidebar
      concept
      conceptName={title}
      leftColumn={
        <div>
          <Heading>Описание:</Heading>
          <DescriptionWrapper
            dangerouslySetInnerHTML={{ __html: conceptCF.description }}
          />
          <Heading>Материалы для ознакомления:</Heading>
          <DocumentsWrapper
            dangerouslySetInnerHTML={{ __html: conceptCF.attachedFiles }}
          />
        </div>
      }
      sticky
    >
      <Gallery content={content} width="33%" />
      <ContentWrapper dangerouslySetInnerHTML={{ __html: content }} />
    </Sidebar>
  )
}

export default Concept

export const pageQuery = graphql`
  query GET_CONCEPT($id: ID!) {
    wordPress {
      concept(id: $id) {
        title(format: RENDERED)
        content(format: RENDERED)
        conceptCF {
          type
          attachedFiles
          description
        }
      }
    }
  }
`
